<template>
	<div id="app-container" dusk="app">
		<div id="app-route-wrapper">
			<main class="main-content container-lg" style="box-shadow: #ccc 0px 2px 10px;">
				<Home />
				<Footer></Footer>
			</main>
		</div>
	</div>
</template>

<script>
import Home from './views/Home'
import Footer from './_Footer'

export default {
	components: { Home, Footer },
	data: () => ({}),
	created() {
		document.title = 'PTA Interactive Tech Note'
	},
	mounted() {
		this.$gtag.pageview('/')
	}
}
</script>
