<template>
	<div>
		<div class="row text-center" style="background: #eee;">
			<div class="col">
				<h3 class="text-center text-dark mt-5 mb-5">
					Compare coverage genome-wide between competing WGA methods
				</h3>
			</div>
		</div>

		<div class="row" @mouseover="highlightIGV = true" @mouseleave="highlightIGV = false" :class="{ IGVLaptopBg: !shouldDrawIGV }">
			<div class="col-md-3 col-sm-12 col-12">
				<WGATitle class="d-md-none"></WGATitle>

				<div class="occupy-space-for-igv-header d-none d-md-block" style="height:250px; " v-if="shouldDrawIGV">
					<div class="form-group pt-7">
						<label for="formControlRange"><h3>Track Height</h3></label>
						<input type="range" class="form-control-range" v-model="trackHeight" min="50" max="600" step="1" />
						<small id="emailHelp" class="form-text text-muted">{{ trackHeight }} px</small>
					</div>
					&nbsp;
				</div>
				<div class="row">
					<div
						v-for="type in types"
						:key="type"
						class="col-md-12 col-sm-3 col-3 compare-selection-title"
						style="padding: 10px; margin-bottom:0px;"
						:style="{ height: shouldDrawIGV ? trackHeight + 'px' : '80px' }"
					>
						<h5 v-bind:style="{ color: typeColor(type), fontWeight: 1200 }">{{ type }}</h5>
						<select
							v-model="selected_sample_names[type]"
							@change="onChange($event)"
							:class="['custom-select', 'custom-select-lg', 'custom-select-' + type]"
							style="text-align-last:center;"
						>
							<option v-for="opt in samples_by_type[type]" :value="opt.sample_name" :key="opt.sample_name">
								{{ opt.sample_name }}
							</option>
						</select>
						<div class="occupy-space-for-igv-track" style="height:135px; ">&nbsp;</div>
					</div>
				</div>
			</div>
			<div class="col-md-9 col-sm-12 col-12" v-if="shouldDrawIGV">
				<WGATitle class="d-none d-md-block"></WGATitle>
				<div class="igv-holder" ref="igvHolder"></div>
			</div>
			<div class="col-md-9 col-12 igv-holder-mobile" v-else @click="promptMobileWarning">
				<img src="../../assets/img/igv-mobile-screenshot.png" width="100%" class="d-none d-sm-block d-md-none" />
				<img src="../../assets/img/igv-mobile-screenshot-portrait.png" width="100%" class="d-block d-sm-none" />
				&nbsp;
			</div>
		</div>

		<div class="row" v-if="Object.keys(selected_sample_names).length > 0">
			<div class="col-lg-3 text-center" @mouseover="highlightCoverage = true" @mouseleave="highlightCoverage = false">
				<CovByProp :selected_samples="selected_samples" :types="types" :colors="colors" />
			</div>
			<div class="col-lg-6">
				<div style="margin-right: 40px;">
					<p class="lead mt-8">Compare features of MDA methods</p>
					<p>
						Data from four different MDA technologies applied to single cells are compared and contrasted.
						<span :class="{ highlight: highlightIGV }">
							The raw alignments can be explored in the embedded IGV viewer (above). This provides an unbiased view of coverage at any particular region in the
							genome and a quick tour will demonstrate that ResolveDNA<sup>&trade;</sup> has coverage uniformity and contiguity equal to or greater than all
							other technologies across the majority of the genome.
						</span>
						<span :class="{ highlight: highlightCoverage }">
							This is confirmed in the coverage plot (left), which shows the coverage distribution across the genome.
						</span>
					</p>
					<p :class="{ highlight: highlightCoverageDepth }">
						Increased coverage breadth is a necessary precondition for identifying genomic variation, but it is not the full story. Genetic mutations can be
						found on either copy of homologous chromosomes, so coverage must be uniform between homologous chromosomes in addition to across chromosomes in
						order to capture the genetic heterogeneity in a single cell.
					</p>
					<p :class="{ highlight: highlightCoverageDepth }">
						This uniformity of coverage between homologous mutations is captured in the alternate allele frequency distribution at heterozygous sites (right).
						This indicates the uniformity of coverage between homologous chromosomes, with samples having most of their distribution centered at 0.5 being the
						most uniform.
					</p>
					<p>
						As is the case with genome coverage breadth, ResolveDNA<sup>&trade;</sup> outperforms all other technologies in coverage uniformity between
						homologous chromosomes. If identifying the maximum possible number of genetic variants from single cells is important, then the choice is clear -
						choose ResolveDNA<sup>&trade;</sup>. It has improved uniformity both across different chromosomes and between homologous chromosomes and is the
						optimal tool for understanding genetic heterogeneity at the single-cell level or where low inputs of DNA are required.
					</p>
				</div>
			</div>
			<div class="col-lg-3 text-center" @mouseover="highlightCoverageDepth = true" @mouseleave="highlightCoverageDepth = false">
				<CovByPropDepth :selected_samples="selected_samples" :types="types" :colors="colors" />
			</div>
		</div>
	</div>
</template>

<script>
import igv from 'igv'
import samples from '@/data/resolve_dna_iwp_metadata.json'
import CovByProp from './CovByProp.vue'
import CovByPropDepth from './CovByPropDepth.vue'
import WGATitle from './WGATitle'
import _ from 'lodash'

export default {
	components: { CovByProp, CovByPropDepth, WGATitle },
	data() {
		return {
			samples,
			screenWidth: 400,
			screenHeight: 385,
			forceIGVLoad: false,
			selected_sample_names: {},
			trackHeight: 105,
			browser: {}, // igv browser object
			igvLoaded: false,
			highlightIGV: false,
			highlightCoverageDepth: false,
			highlightCoverage: false,
			locus: '8:80,192,136-80,197,127',
			colors: ['#FFBE0B', '#FB5607', '#3A86FF', 'black', 'grey', 'darkgreen', 'pink', 'brown', 'slateblue', 'grey1', 'orange']
		}
	},
	computed: {
		locusChr() {
			return this.locus.split(':')[0]
		},
		locusWindow() {
			return this.locus.split(':')[1]
		},

		// if we are not on a mobile, or we have been asked to force IGV Load,
		shouldDrawIGV() {
			return !this.isMobile || this.forceIGVLoad
		},

		isMobile() {
			return this.screenWidth <= 550 || this.screenHeight <= 385 // most phones are under 500px
		},
		types() {
			return _.uniq(_.map(this.samples, 'condition'))
		},
		samples_by_type() {
			return _.groupBy(this.samples, 'condition')
		},

		selected_samples() {
			const samples = {}
			this.types.forEach(type => {
				samples[type] = this.samples_by_type[type].find(a => a.sample_name == this.selected_sample_names[type])
			})
			return samples
		},
		options() {
			return {
				showNavigation: true,
				showRuler: true,
				reference: {
					id: 'hg38',
					name: 'Human (GRCh38/hg38)',
					fastaURL: 'https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa',
					indexURL: 'https://s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa.fai',
					cytobandURL: 'https://s3.amazonaws.com/igv.org.genomes/hg38/annotations/cytoBandIdeo.txt.gz'
				},
				locus: this.locus,
				tracks: this.igv_tracks
			}
		},

		igv_tracks() {
			var tracks = []

			for (let index in this.types) {
				let type = this.types[index]
				tracks.push({
					trailblazerType: type, // embed type
					type: 'alignment',
					format: 'bam',
					url: this.selected_samples[type].gcp_bam_location,
					indexURL: this.selected_samples[type].gcp_index_location,
					name: this.selected_sample_names[type],
					color: this.typeColor(type),
					order: index,
					sort: {
						chr: 'chr8',
						position: 80192136,
						option: 'BASE',
						direction: 'ASC'
					},
					height: this.trackHeight
				})
			}
			return tracks
		}
	},
	watch: {
		trackHeight(newVal, oldVal) {
			// do some work here
			if (oldVal != newVal && this.igvLoaded) {
				this.browser.trackViews[3].setTrackHeight(newVal)
				this.browser.trackViews[4].setTrackHeight(newVal)
				this.browser.trackViews[5].setTrackHeight(newVal)

				this.$gtag.event('igv-track-height-updated', {
					event_category: 'track-height',
					event_label: 'track-height',
					value: newVal
				})
			}
		},
		selected_samples(newVal, oldVal) {
			if (this.igvLoaded) {
				this.types.forEach(type => {
					if (oldVal[type] == undefined) return
					else if (oldVal[type].sample_name !== newVal[type].sample_name) {
						this.browser.removeTrackByName(oldVal[type].sample_name)
					}
				})
			}
		}
	},
	mounted() {
		this.init()
		this.draw()
	},
	methods: {
		typeColor(type) {
			var index = this.types.indexOf(type)
			if (index === -1) index = 0

			return this.colors[index]
		},

		// update selected_sample_names
		init() {
			this.screenWidth = screen.width
			this.screenHeight = screen.height
			for (let type of this.types) {
				this.$set(this.selected_sample_names, type, this.samples_by_type[type][0].sample_name)
			}
		},

		onChange(event) {
			if (!this.igvLoaded) {
				return this.promptMobileWarning()
			}

			const changed_sample = this.samples.find(a => a.sample_name == event.target.value)
			const changed_type = changed_sample.condition

			var trackIndex = _.findIndex(this.igv_tracks, { trailblazerType: changed_type })

			if (trackIndex === -1) {
				console.error('Couldn not find corresponding igv track in array')
			} else {
				this.$gtag.event('change_sample', {
					event_category: 'interact',
					event_label: changed_sample.condition,
					value: changed_sample.sample_name
				})

				this.browser.loadTrack(this.igv_tracks[trackIndex])
			}
		},

		draw() {
			// this.clearDOM();
			if (this.shouldDrawIGV) {
				if (this.$refs.igvHolder == undefined) {
					// draw again
					return setTimeout(() => {
						this.draw()
					}, 200)
				}
				igv.createBrowser(this.$refs.igvHolder, this.options).then(browser => {
					this.browser = browser
					this.igvLoaded = true
					console.log('igv loaded')

					this.$gtag.event('igv-loaded', {
						event_category: 'igv-loaded',
						event_label: 'igv-loaded',
						value: 'browser loaded'
					})

					this.browser.on('trackclick', function() {
						return false
					})
					this.browser.on('locuschange', referenceFrame => {
						if (referenceFrame.start == '1') {
							// most likely the chromosome has changed
							console.log(referenceFrame)

							// lets preserve the locus position but in the new chr
							let newChr = referenceFrame.chr.replace('chr', '')
							if (this.locusChr != newChr) {
								// the chr has changed
								// lets preserve its position
								let newLocus = newChr + ':' + this.locusWindow
								this.locus = newLocus
								this.browser.search(this.locus)
							}
						} else {
							// update our locus
							this.locus = referenceFrame.label.replace('chr', '')
						}
					})
				})
			}
		},

		// ask the user for what to do
		promptMobileWarning() {
			if (this.isMobile) {
				this.$bvModal
					.msgBoxConfirm(
						'The integrated genome browser uses a significant amount of RAM and is not suitable for mobile devices. You can still load the visualization if you want to try, but we recommend using a desktop or a tablet to have the best experience.',
						{
							class: '123',
							title: 'Load IGV?',
							size: 'xs',
							buttonSize: 'sm',
							okVariant: 'warning',
							okTitle: 'Load IGV anyway',
							cancelVariant: 'info',
							cancelTitle: 'Cancel',
							footerClass: 'p-2 force-justify-modal-footer',
							hideHeaderClose: false,
							centered: true
						}
					)
					.then(value => {
						if (value) {
							// clicked on load igv
							// lets load IGV
							this.forceIGVLoad = true
							this.$nextTick(() => {
								setTimeout(() => {
									this.draw()
								}, 500)
							})
						} else {
							// nothing to do here...
						}
					})
					.catch(() => {
						// An error occurred
					})
			}
		}
	}
}
</script>

<style>
@media (max-height: 576px) {
	select.custom-select {
		font-size: 1.9vw !important;
	}
}
@media (max-height: 1040px) {
	select.custom-select {
		font-size: 1.5vw !important;
	}
}

select.custom-select-TYPE1 {
	background-color: #ffda73;
}
select.custom-select-TYPE2 {
	background-color: #fba983;
}
select.custom-select-TYPE3 {
	background-color: #a6c8ff;
}

select.custom-select.custom-select-lg {
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
	color: #000;

	padding: 0px;
	font-size: 1.3em;
	font-weight: bold;
}

.IGVLaptopBg select.custom-select {
	font-size: 1em !important;
}

.igv-root {
	margin-top: 24px !important;
}

.igv-holder {
	margin: auto;
	margin-top: 30px;
	margin-bottom: 50px;
	padding: 20px;
	border-radius: 5px;
	border: 1px solid #eee;
	border-right: 0px;
	box-shadow: -15px -1px 23px -4px rgba(118, 118, 118, 0.52);
}

div.igv-style-title {
	text-align: center;
	font-size: 145%;
	margin: auto;
	width: 100%;
}
div.igv-root-title {
	text-align: center;
	font-size: 145%;
	position: absolute;
	top: 33px;
	margin: auto;
	width: 100%;
}

.highlight {
	background-color: #fff9c0;
}

.modal-footer {
	justify-content: inherit !important;
}
.modal-xs {
	max-width: 400px;
	top: -20px;
}
.igv-holder-mobile {
	min-height: 200px;
}
.compare-selection-title h5 {
	font-size: 1.05rem;
}

@media (min-width: 576px) {
	.compare-selection-title h5 {
		font-size: inherit;
	}
}
</style>
