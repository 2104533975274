<template>
	<div class="text-center">
		<div class="igv-style-title mt-5">
			Coverage by genome Proportion
			<span
				v-b-popover.hover="
					'The graph indicates the proportion of the genome (y-axis) that has coverage at the level specified in the x-axis. Genome coverage was computed for all samples at 300 million paired-end reads per cell and over regions totalling 2,745,186,691 bases.'
				"
				title="Methods - Computing genome coverage proportion"
			>
				<b-icon icon="info-circle-fill"></b-icon>
			</span>
		</div>
		<div style="width: 93%; " ref="coverageDiv"></div>
	</div>
</template>

<script>
import embed from 'vega-embed'
import samples from '@/data/resolve_dna_iwp_cov.json'
import _ from 'lodash'

export default {
	props: ['selected_samples', 'types', 'colors'],
	data() {
		return {
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
			samples
		}
	},
	watch: {
		selected_samples() {
			this.render()
		}
	},
	computed: {
		plot_values: function() {
			let values = []
			this.types.forEach(type => {
				const sam_name = this.selected_samples[type].sample_name
				const sams = samples[sam_name]
				if (sams !== undefined && sams.length) values = values.concat(...sams)
			})
			return values
		},
		sample_names() {
			return _.map(this.selected_samples, 'sample_name')
		},
		sample_names_tooltip() {
			var arr = []
			for (var sample_name of this.sample_names) {
				arr.push({ field: sample_name, type: 'quantitative', format: '.2f' })
			}
			return arr
		}
	},
	methods: {
		def() {
			return {
				$schema: 'https://vega.github.io/schema/vega-lite/v4.json',
				description: 'Pecent Genome by WGS coverage',
				width: 'container',
				height: 270,
				data: { values: this.plot_values },
				encoding: {
					x: {
						field: 'cov_bin',
						type: 'quantitative',
						title: 'Coverage',
						sort: 'ascending',
						axis: { grid: false, titleFontSize: 18, labelFontSize: 16 }
					}
				},
				layer: [
					{
						encoding: {
							color: {
								field: 'sample_name',
								type: 'nominal',
								scale: {
									domain: this.sample_names,
									range: this.colors
								},
								legend: null
							},
							y: {
								field: 'binned_prop',
								type: 'quantitative',
								title: 'Genome proportion',
								axis: { grid: true, titleFontSize: 18, labelFontSize: 16 },
								scale: { domainMax: 0.45 }
							}
						},
						layer: [{ mark: 'line' }, { transform: [{ filter: { selection: 'hover' } }], mark: 'point' }]
					},
					{
						transform: [{ pivot: 'sample_name', value: 'binned_prop', groupby: ['cov_bin'] }],
						mark: 'rule',
						encoding: {
							opacity: {
								condition: { value: 0.3, selection: 'hover' },
								value: 0
							},
							tooltip: [
								{
									field: 'cov_bin',
									title: 'Coverage',
									type: 'quantitative'
								},
								...this.sample_names_tooltip
							]
						},
						selection: {
							hover: {
								type: 'single',
								fields: ['cov_bin'],
								nearest: true,
								on: 'mouseover',
								empty: 'none',
								clear: 'mouseout'
							}
						}
					}
				]
			}
		},

		render() {
			embed(this.$refs.coverageDiv, this.def(), { actions: false })
		}
	},
	mounted() {
		this.render()
	}
}
</script>
