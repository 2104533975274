<template>
	<div style="width:100%">
		<div class="igv-style-title text-center mt-5">
			Alternate allele frequencies
			<span
				v-b-popover.hover="
					'Allele frequences were computed by calculating the alternate allele frequencies at bi-allelic heterozygous germline SNP variants. These sites are defined as heterozygous sites that were shared between the majority of single cells from the same individual and are categorized as common variants dbSNP v151.'
				"
				title="Methods - Computing allele frequencies"
			>
				<b-icon icon="info-circle-fill"></b-icon>
			</span>
		</div>
		<div style="width: 90%; left:-50px" ref="coverageDiv"></div>
		<div>
			<label style="font-size: 120%"> Min Depth </label>
			<div>
				<div v-for="depth in depths" :key="depth" style="display: inline-block">
					<button
						type="button"
						class="btn btn-sm btn-round m-2"
						@click="onChange($event)"
						:value="depth"
						:key="depth"
						style="border:1px solid #999;"
						:class="depth == selected_depth ? 'btn-primary' : 'btn-light'"
					>
						{{ depth }} x
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import embed from 'vega-embed'
import samples from '@/data/resolve_dna_iwp_ado.json'
//import _ from 'lodash'

export default {
	props: ['selected_samples', 'types', 'colors'],
	data() {
		return {
			msg: '',
			description:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
			samples,
			depths: ['1', '5', '10', '15'],
			selected_depth: '',
			resizeHander: null
		}
	},
	watch: {
		selected_samples: function() {
			this.render()
		}
	},
	computed: {
		plot_values: function() {
			let values = []
			this.types.forEach(type => {
				const sam_name = this.selected_samples[type].sample_name
				const sams = samples[sam_name]
				if (sams !== undefined) {
					values = values.concat(...sams[this.selected_depth])
				}
			})
			return values
		},
		sample_names() {
			var names = []
			// this has to be in the order of the types
			for (let type of this.types) {
				names.push(this.selected_samples[type].sample_name)
			}
			return names
			//return _.map(this.selected_samples, 'sample_name')
		},
		sample_names_tooltip() {
			var arr = []
			for (var sample_name of this.sample_names) {
				arr.push({ field: sample_name, type: 'quantitative', format: '.2f' })
			}
			return arr
		}
	},
	created() {
		this.get_depth()
	},
	methods: {
		onChange(event) {
			this.selected_depth = event.target.value
			embed(this.$refs.coverageDiv, this.def(), { actions: false })
		},
		get_depth() {
			this.selected_depth = this.depths[0]
		},
		def() {
			return {
				$schema: 'https://vega.github.io/schema/vega-lite/v4.json',
				description: 'Pecent Genome by WGS coverage',
				width: 'container',
				autosize: { type: 'fit', contains: 'padding' },

				height: 80,
				data: { values: this.plot_values },
				mark: 'bar',
				encoding: {
					x: {
						field: 'freq_bin',
						type: 'quantitative',
						title: 'Frequency',
						sort: 'ascending',
						axis: { grid: false, titleFontSize: 18, labelFontSize: 16 }
					},
					y: {
						field: 'binned_prop',
						type: 'quantitative',
						title: '',
						axis: { grid: true, titleFontSize: 18, labelFontSize: 16 },
						scale: { domainMax: 0.5 }
					},
					color: {
						field: 'sample_name',
						type: 'nominal',
						scale: {
							domain: this.sample_names,
							range: this.colors
						},
						legend: null,
						axis: { titleFontSize: 18, labelFontSize: 16 }
					},
					row: {
						field: 'sample_name',
						type: 'ordinal',
						title: '',
						sort: this.sample_names,
						header: {
							labelFontSize: 12
						}
					},

					tooltip: [
						{ field: 'binned_prop', type: 'quantitative', title: 'Genome Proportion', format: '.2f' },
						{
							field: 'freq_bin',
							title: 'Frequency',
							type: 'quantitative'
						}
					]
				},
				config: {
					bar: {
						continuousBandSize: 16
					}
				}
			}
		},
		render() {
			embed(this.$refs.coverageDiv, this.def(), { actions: false })
		}
	},
	mounted() {
		this.render()
		this.resizeHandler = window.addEventListener('resize', () => this.render())
	}
}
</script>
