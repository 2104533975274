import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueGtag from 'vue-gtag'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import './assets/app.scss'

Vue.config.productionTip = false

if (process.env.VUE_APP_GA_TRACKING_ID) {
	Vue.use(VueGtag, {
		config: { id: process.env.VUE_APP_GA_TRACKING_ID },
		params: {
			send_page_view: true
		}
	})
} else {
	// load the gtag api for dev, but do not track anything
	Vue.use(VueGtag, { enabled: false, disableScriptLoad: true })
}

new Vue({
	//  router,
	render: h => h(App)
}).$mount('#app')
