<template>
	<div>
		<Header />
		<Igv />
	</div>
</template>

<script>
import Header from './home/Header.vue'
import Igv from './home/Igv.vue'

export default {
	components: { Header, Igv }
}
</script>
